import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '../views/Home.vue'

import Admin from "@/layouts/Admin.vue";

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/details/:school',
    name: 'Details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "details" */ '../views/Details.vue')
  },
  {
    path: '/compare',
    name: 'Compare',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "compareView" */ '../views/CompareView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue')
  },
  {
		path: '/logout',
		name: 'Logout',
		// beforeEnter (to, from, next) {
		// 	logout();
		// 	next('/login');
		// },
	},
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/admin/users",
        name: 'Users',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_users" */ '../views/admin/Users.vue'),
      },
      {
        path: "/admin/schools",
        name: 'Schools',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_schools" */ '../views/admin/Schools.vue'),
      },
      {
        path: "/admin/requests",
        name: 'AdminRequests',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "admin_requests" */ '../views/admin/AdminRequest.vue'),
      },
      {
        path: "/admin/school_updates",
        name: 'SchoolUpdates',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "school_updates" */ '../views/admin/SchoolUpdates.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
