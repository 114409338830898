<template>
  <div>
    <Map />
  </div>
</template>

<script>
import Map from "@/components/Map.vue";

export default {
  name: "Home",
  components: { Map, },
};
</script>

<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
}
</style>
